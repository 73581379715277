.header {
  padding: 10px 0 10px;
  margin: 0;
  @media screen and (max-width: 1123px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 0 10px;
  }
}
.header-title {
  font-family: "ApercuProBold";
  font-size: 30px;
  font-weight: 600;
  @media screen and (max-width: 1123px) {
    width: 100%;
    text-align: center;
  }
}
.header-sidebar {
  height: 100%;
  @media screen and (max-width: 1123px) {
    margin: 0;
  }
}
.header-box {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  max-width: max-content;
  @media screen and (max-width: 1123px) {
    padding: 0;
    margin: 10px 10px 0;
    width: calc(100% - 20px);
    min-width: calc(100% - 20px);
  }
}
