@font-face {
  font-family: "ApercuProBoldItalic";
  src: url("./apercu/Apercu\ Bold\ Italic.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "ApercuProBold";
  src: url("./apercu/Apercu\ Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "ApercuProMediumItalic";
  src: url("./apercu/Apercu\ Medium\ Italic.ttf");
  font-weight: medium;
  font-style: italic;
}
@font-face {
  font-family: "ApercuProMedium";
  src: url("./apercu/Apercu\ Medium.ttf");
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: "ApercuProLightItalic";
  src: url("./apercu/Apercu\ Light\ Italic.ttf");
  font-weight: medium;
  font-style: italic;
}
@font-face {
  font-family: "ApercuProLight";
  src: url("./apercu/Apercu\ Light.ttf");
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: "ApercuProRegular";
  src: url("./apercu/Apercu_Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
