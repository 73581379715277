.filters-block {
  height: -webkit-fill-available;
  background-color: white;
  width: inherit !important;
  z-index: 1000;
  display: inherit;
  width: 500px;
  min-width: 500px;
  position: absolute;
  max-width: max-content;
  transform: translateX(-105%);
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  @media screen and (max-width: 1123px) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    min-width: 100%;
    display: inherit;
    margin: 0;
    z-index: 99999;
  }
  &.visible {
    transform: translateX(0);
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    @media screen and (max-width: 1123px) {
      transform: translateX(0);
    }
  }
  .btns-wrapper {
    display: flex;
    flex-direction: row;
    gap: 25px;
    padding: 0 25px;
    padding-bottom: 25px;
    width: calc(100% - 86px);
    @media screen and (max-width: 1123px) {
      padding: 0 10px;
      width: calc(100% - 20px);
    }
  }
  .applyButton {
    height: auto;
    width: auto;
    min-width: calc((100% - 25px) / 2);
    padding: 10px 20px;
    border-radius: 12px;
    background-color: black;
    font-family: "ApercuProRegular";
    font-size: 17px;
    font-weight: 400;
    text-transform: none;
    margin: 0;
    &:hover {
      background-color: #334091;
      border: none;
    }
    @media screen and (max-width: 1123px) {
      padding: 5px 15px;
      font-size: 16px;
    }
  }
  .backButton {
    height: auto;
    width: auto;
    min-width: calc((100% - 25px) / 2);
    padding: 10px 20px;
    border-radius: 12px;
    background-color: white;
    margin: 0;
    border-color: black;
    color: black;
    font-family: "ApercuProRegular";
    font-size: 17px;
    font-weight: 400;
    text-transform: none;
    &:hover {
      background-color: #000000;
      border: 2px solid black;
      color: white;
    }
    @media screen and (max-width: 1123px) {
      padding: 5px 15px;
      font-size: 16px;
    }
  }
  .filters-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 57px;
    @media screen and (max-width: 1123px) {
      padding-top: 0;
    }
  }
  .filters-item:first-child {
    cursor: pointer;
  }
  .filters-item:not(:first-child) {
    border-top: 1px solid #f2f2f2;
  }
  .filter-item__header {
    position: relative;
    display: flex;
    min-width: calc(100% - 100px);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 33px 25px;
    color: black;
    font-family: "ApercuProRegular";
    font-size: 22px;
    font-weight: 400;
    text-transform: none;
    @media screen and (max-width: 1123px) {
      padding: 15px 10px;
    }
  }
  .arrow-icon {
    position: absolute;
    top: calc(50% - 15px);
    right: 25px;
    width: 30px;
    height: 30px;
    fill: #707070;
    @media screen and (max-width: 1123px) {
      right: 20px;
    }
  }
}
.companies-list {
  padding: 0 25px 33px;
  display: flex;
  align-items: space-between;
  @media screen and (max-width: 1123px) {
    padding: 0 10px 30px;
  }
  .companies-item {
    display: flex;
    align-items: center;
    gap: 10px;
    .checkbox {
      padding: 0;
      width: 24px;
      height: 24px;
      svg {
        color: black;
      }
      &-icon {
        color: black;
        border-radius: 5px;
      }
    }
    .mobile-icon {
      width: 24px;
      height: 24px;
      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.select-all {
  color: #a3a3a3;
  font-family: "ApercuProRegular";
  font-size: 14px;
  font-weight: 500;
  padding-right: 50px;
  @media screen and (max-width: 1123px) {
    padding-right: 40px;
  }
}
.filters-header {
  padding: 10px 25px;
  display: flex;
  align-items: center;
  gap: 24px;
  position: relative;
  border-bottom: 1px solid #f2f2f2;
  min-width: calc(100% - 100px);
  @media screen and (max-width: 1123px) {
    padding: 20px;
  }
  .filters-icon {
    @media screen and (max-width: 1123px) {
      display: none;
    }
  }
  .div-title {
    font-family: "ApercuProBold";
    font-size: 30px;
    font-weight: 700;
    @media screen and (max-width: 1123px) {
      width: 100%;
      justify-content: center;
      font-size: 22px;
      line-height: 22px;
      text-align: center;
    }
  }
  .close-btn {
    position: absolute;
    top: calc(50% - 15px);
    right: 25px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    @media screen and (max-width: 1123px) {
      top: 15px;
      right: 10px;
    }
  }
}
