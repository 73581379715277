.point-details-title {
  font-family: "ApercuProBold";
  font-size: 22px;
  font-weight: 600;
  padding-right: 36px;
  @media screen and (max-width: 1123px) {
    font-size: 16px;
    padding-right: 70px;
  }
}
.point-details-address {
  font-family: "ApercuProRegular";
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  padding-right: 36px;

  @media screen and (max-width: 1123px) {
    font-size: 14px;
    padding-left: 5px !important;
  }
}
.point-details-card {
  font-family: "ApercuProRegular";
  font-size: 14px;
  font-weight: 400;
  padding: 10px 0 0;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 1123px) {
    font-size: 14px;
    margin-top: 10px;
    padding: 10px 5px;
    border-top: 1px solid #f2f2f2;
    gap: 7px;

    svg {
      max-height: 16px;
    }
  }
}
.point-details-divider {
  height: auto;
  @media screen and (max-width: 1123px) {
    display: none;
  }
}
.point-details {
  display: flex;
  & > svg {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  @media screen and (max-width: 1123px) {
    margin-left: 0;
    left: 0;
    padding: 0;
    width: 100%;
    position: relative;
    padding: 0 !important;
    flex-wrap: wrap;
    max-height: 150px;
    overflow-y: auto;
  }
}
.point-details-column {
  width: 60%;
  max-width: 60%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1123px) {
    padding-left: 0 !important;
    left: 0;
    position: relative;
    width: 100%;
    max-width: 100%;
  }
}
.footer-class {
  background-color: white;
  max-width: calc(100% - 40px);
  box-sizing: border-box;
  width: 710px;
  padding: 30px;
  border-radius: 12px 12px 0 0;
  margin: 0 20px;
  position: relative;
  max-height: 300px;

  .point-distance {
    top: 7px;
    right: 0;
  }
  & > div {
    margin-top: 0 !important;
    @media screen and (max-width: 1123px) {
      justify-content: center;
    }
  }
  @media screen and (max-width: 1123px) {
    padding: 10px;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
}
.mobile-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  padding-top: 5px;
  svg {
    width: 24px;
    height: 24px;
  }
  @media screen and (max-width: 1123px) {
    padding-top: 0;
  }
}
.choosePointButton {
  height: auto;
  width: auto;
  padding: 10px 20px;

  border-radius: 12px;
  background-color: black;
  font-family: "ApercuProRegular";
  font-size: 17px;
  font-weight: 400;
  text-transform: none;
  &:hover {
    background-color: #334091;
    border: none;
  }
  @media screen and (max-width: 1123px) {
    width: auto;
    padding: 5px 15px;
    font-size: 16px;
  }
}
.backButton {
  height: auto;
  width: auto;
  margin-right: 20px;
  border-radius: 12px;
  padding: 10px 20px;
  background-color: white;
  border: 2px solid #f2f4f8;
  color: black;
  font-family: "ApercuProRegular";
  font-size: 17px;
  font-weight: 400;
  text-transform: none;
  &:hover {
    background-color: #000000;
    border: 2px solid black;
    color: white;
  }
  @media screen and (max-width: 1123px) {
    padding: 5px 15px;
    font-size: 16px;
  }
}
.opening-hours {
  flex-wrap: nowrap;
  min-width: 200px;
  div {
    flex-wrap: nowrap;
  }
  p {
    white-space: nowrap;
  }
  .MuiTypography-body2 {
    font-size: 16px;
    line-height: 25px;
    white-space: pre-line;
    max-width: 100px;
  }
  @media screen and (max-width: 1123px) {
    width: max-content;
    max-width: max-content;
    padding-left: 5px;
    padding-bottom: 50px;
  }
}
