.logo {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 55px;
  min-width: 55px;
  @media screen and (max-width: 1123px) {
    width: 40px;
    min-width: 40px;
  }
  p {
    font-family: "ApercuProLight";
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
  }
  svg {
    min-width: 52px;
    max-height: 23px;
    height: auto;
    @media screen and (max-width: 1123px) {
      min-width: 25px;
      max-height: 33px;
    }
  }
}
.logo-text {
  @media screen and (max-width: 1123px) {
    display: none;
  }
}
.divider {
  margin: 15px;
  @media screen and (max-width: 1123px) {
    border-color: transparent;
    margin: 5px;
  }
}
.divider-bottom {
  &.hide {
    display: none;
  }
}

.point-title {
  font-family: "ApercuProBold";
  font-size: 18px;
  font-weight: 600;
  padding: 0 20px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 70px;
  @media screen and (max-width: 1123px) {
    padding-right: 80px;
  }
}
.point-address {
  font-family: "ApercuProRegular";
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  padding-right: 50px;
}
.point-distance {
  position: absolute;
  top: 18px;
  right: 15px;
  font-family: "ApercuProRegular";
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  color: rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 1123px) {
    right: 15px;
  }
}
.list-item-button {
  position: relative;
  border-radius: 0;
  @media screen and (max-width: 1123px) {
    padding: 5px 10px;
    align-items: flex-start;
  }
  &:hover {
    background-color: #f2f4f8;
    border-radius: 12px;
    margin: 1px 0;
  }
  &.selected {
    background-color: #f2f4f8;
    border-radius: 12px;
  }
}
.box-item:first-child:hover {
  .list-item-button {
    margin-top: 0 !important;
  }
}
.box-item.selected:hover {
  .list-item-button {
    margin-bottom: 0 !important;
  }
}
.box-item {
  position: relative;
  border-radius: 0;
  &:hover {
    border-radius: 12px;
    .divider-bottom {
      display: none;
    }
  }
  &.selected {
    border-radius: 12px;
    border-bottom: 1px solid white;
  }
}

.box-item.selected:has(+ .box-item:hover) {
  margin-bottom: -1px;
}
.box-item:has(+ .box-item:hover) {
  .divider-bottom {
    display: none;
  }
}
.list-item-button:hover,
.box-item:hover {
  @media screen and (max-width: 1123px) {
    border-radius: 0;
  }
}

.input-field {
  width: -webkit-fill-available;
  height: 100%;
  border-radius: 12px;
  background-color: #f2f4f8;
  border: none;
  transition: all 0.5s;
  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
  @media screen and (max-width: 1123px) {
    width: 70%;
  }
  &.focused {
    width: 88%;
    transition: all 0.5s;
  }
  .MuiInputBase-root {
    gap: 15px;
    height: 100%;
    padding: 15px 20px;
    @media screen and (max-width: 1123px) {
      padding: 10px 10px;
    }
  }
  input {
    font-family: "ApercuProRegular";
    font-size: 14px;
    font-weight: 400;
    color: #5d5d5d;
    height: max-content;
    padding: 0;
    &::placeholder {
      color: #5d5d5d;
    }
  }
}
.search-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 16px;
  padding-bottom: 10px;
  @media screen and (max-width: 550px) {
    gap: 10px;
  }
}
.filters-box {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  border: 2px solid #f2f4f8;
  padding: 15px 20px;
  justify-content: space-between;
  height: auto;
  width: max-content;
  align-items: center;
  gap: 10px;
  p {
    font-family: "ApercuProRegular";
    font-size: 14px;
    font-weight: 400;
  }
  @media screen and (max-width: 1123px) {
    padding: 10px 15px;
  }
}
.filters-count {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  background-color: black;
  border-radius: 50%;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "ApercuProRegular";
  font-size: 10px;
  font-weight: 400;
}

.filter-item {
  width: min-content;
  border-radius: 4px;
  background-color: #f2f4f8;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 8px;
  height: 12px;
  margin-bottom: 10px;

  p {
    font-family: "ApercuProLight";
    font-size: 12px;
    font-weight: 300;
    white-space: nowrap;
  }
  svg {
    cursor: pointer;
  }
}

.list {
  @media screen and (max-width: 1123px) {
    position: absolute;
    background: white;
    min-height: 120px;
    max-height: 120px;
    bottom: 0;
    z-index: 9999;
    left: 0;
    max-width: 100%;
    min-width: 100%;
    padding-right: 0 !important;
    padding-top: 0 !important;
    transition: all 0.5s;
    margin-right: 0;
    transform-origin: bottom;
  }
  &.opened {
    @media screen and (max-width: 1123px) {
      max-height: 350px;
      min-height: 350px;
    }
  }
  &.focused {
    min-height: calc(100svh - 75px);
    max-height: calc(100svh - 75px);
    transition: all 0.5s;
    transform-origin: bottom;
  }
}
.opened-toggle {
  width: 55px;
  height: 49px;
  border-radius: 12px 12px 0 0;
  background-color: white;
  position: absolute;
  background: white;
  bottom: 125px;
  z-index: 999;
  left: calc(50% - 27px);
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  &.opened {
    bottom: 355px;
  }
  &.focused {
    display: none;
  }
  @media screen and (max-width: 1123px) {
    display: flex;
  }
}
